<template>
  <CHeader position="sticky" class="mb-3">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="()=>$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" />
      </CHeaderToggler>
      <CHeaderNav class="me-auto">
        <CNavItem>
          <CNavLink  class="page-title"> {{ $t(`${$route.meta.title}`) }} </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <CButton v-if="showButton" @click="backToAdmin" color="primary" shape="rounded-0" size="sm"><CIcon :icon="cilArrowLeft" size="20"  />
          {{ $t('back_admin') }}</CButton>
        <CDropdown variant="btn-group" @click="null">
          <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
            <CIcon :icon="cilLanguage" size="xl" />
          </CDropdownToggle>
          <CDropdownMenu class="pt-0">
            <CDropdownItem :class="[$i18n.locale === 'en' ? 'active-lang': '']" @click="changeLang('en')">English</CDropdownItem>
            <CDropdownItem :class="[$i18n.locale === 'da' ? 'active-lang': '']" @click="changeLang('da')">Danish</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
<!--        <CFormSelect size="sm" @change="changeLang($event)">-->
<!--          <option value="en"></option>-->
<!--          <option value="da"></option>-->
<!--        </CFormSelect>-->
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
<!--    <CHeaderDivider />-->
<!--    <CContainer fluid>-->
<!--      <AppBreadcrumb />-->
<!--    </CContainer>-->
  </CHeader>
</template>

<script>
// import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { CIcon } from '@coreui/icons-vue';
import showErrors from "@/mixins/showErrors";
import { cilLanguage, cilArrowLeft } from '@coreui/icons';

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    CIcon,
    AppHeaderDropdownAccnt,
  },
  mixins:[showErrors],
  data(){
    return{
      logo,
      cilLanguage,
      cilArrowLeft
    }
  },
  computed:{
    showButton(){
      return JSON.parse(this.$store.getters['auth/getAdminLogin'])
    }
  },
  methods: {
    async changeLang(lang){
      await this.$store.commit("auth/CHANGE_LANG", lang)
      this.$i18n.locale = lang
      if (this.$route.name === 'addObject' || this.$route.name === 'editObject'){
        location.reload()
      }
        // this.emitter.emit('recallObject')
    },
    async backToAdmin(){
     let item = JSON.parse(localStorage.getItem('userState'))
     if (item){
       item.isAdminLogged = false
       await this.$store.commit('auth/CHANGE_ADMIN_LOGIN', "false")
       await this.$store.commit("auth/setUser", item)
       if (this.$route.name === 'Dashboard'){
         location.reload()
       }else{
         this.$router.push({name: 'Dashboard'})
       }
     }
    }
  },
}
</script>

<style lang="scss">
.header-nav{
  .btn-group{
    display: flex;
    align-items: center;
    .btn{ color: black; padding: .5rem; svg{ height: 1.3rem; width: 1.3rem; } }
    .show, .btn:hover{ border-color: transparent !important; }
    .dropdown-item{ cursor: pointer !important; }
  }
}
.active-lang{
  background-color: #0C1892 !important;
  color: white !important;
}
</style>
