
import { createRouter, createWebHashHistory  } from 'vue-router'

import store from "@/store";



const routes = [
  { path: '/', name: 'Dashboard', meta: { title: 'Dashboard' }, component: ()=> import(/* webpackChunkName: "Dashboard" */ '@/views/dashboard/Dashboard.vue')},
  { path: '/companies', name: 'companies', meta: { title: 'Companies' }, component: ()=> import(/* webpackChunkName: "Companies" */ '@/views/company/companies.vue')},
  { path: '/company/add-company', meta: { title: 'Companies' }, name:'addCompany', component:()=> import(/* webpackChunkName: "addCompanies" */ '@/views/company/add-company.vue')},
  { path: '/company/:id/update-company/:show?', name:'editCompany', meta: { title: 'Companies' }, component:()=> import(/* webpackChunkName: "viewCompanies" */ '@/views/company/add-company.vue')},
  { path: '/staff', name: 'staff', meta: { title: 'Staff' }, component: ()=> import(/* webpackChunkName: "Staff" */ '@/views/Staff/staff.vue') },
  { path: '/staff/add-staff', meta: { title: 'Staff' }, name:'addStaff', component:()=> import(/* webpackChunkName: "addStaff" */ '@/views/Staff/add-staff.vue')},
  { path: '/staff/:id/update-staff/:show?', meta: { title: 'Staff' }, name:'updateStaff', component:()=> import(/* webpackChunkName: "updateStaff" */ '@/views/Staff/add-staff.vue')},
  { path: '/users', name: 'Users', meta: { title: 'Users' }, component: ()=> import(/* webpackChunkName: "Users" */ '@/views/users/users.vue') },
  { path: '/user/new-user', name: 'addUser', meta: { title: 'Users' }, component: ()=> import(/* webpackChunkName: "addUsers" */ '@/views/users/add-user.vue') },
  { path: '/user/:id/update-user/:show?', name: 'editUser', meta: { title: 'Users' }, component: ()=> import(/* webpackChunkName: "editUsers" */ '@/views/users/edit-user.vue') },
  { path: '/cars', name: 'Cars', meta: { title: 'Users' }, component: ()=>import(/* webpackChunkName: "Cars" */ '@/views/cars.vue') },
  { path: '/car/new-car', name: 'addCar', meta: { title: 'add_car' }, component: ()=>import(/* webpackChunkName: "addCars" */ '@/views/add-car.vue') },
  { path: '/objects/:search?', name: 'Objects', meta: { title: 'Objects' }, component: ()=>import(/* webpackChunkName: "objects" */ '@/views/objects/objects.vue') },
  { path: '/object/add-object/:user?/:company?', name: 'addObject', meta: { title: 'Objects' }, component: ()=>import(/* webpackChunkName: "addObjects" */ '@/views/objects/add-object.vue') },
  { path: '/object/:id/update-object/:show?', name: 'editObject', meta: { title: 'Objects' }, component: ()=>import(/* webpackChunkName: "editObject" */ '@/views/objects/add-object.vue') },
  { path: '/object/types', name: 'Types', meta: { title: 'types' }, component: ()=>import(/* webpackChunkName: "types" */ '@/views/objects/object-types.vue') },
  { path: '/object/add-type', name: 'addType', meta: { title: 'types' }, component: ()=>import(/* webpackChunkName: "addTypes" */ '@/views/objects/add-type.vue') },
  { path: '/object/types/:id/update-type', name: 'editType', meta: { title: 'types' }, component: ()=>import(/* webpackChunkName: "EditType" */ '@/views/objects/add-type.vue') },
  { path: '/object/status', name: 'ObjectStatus', meta: { title: 'object_status' }, component: ()=>import(/* webpackChunkName: "object-status" */ '@/views/objects/object-status.vue') },
  { path: '/objects/add-status', name: 'addStatus', meta: { title: 'object_status' }, component: ()=>import(/* webpackChunkName: "add-object-status" */ '@/views/objects/add-status.vue') },
  { path: '/objects/:id/update-status', name: 'editStatus', meta: { title: 'object_status' }, component: ()=>import(/* webpackChunkName: "edit-object-status" */ '@/views/objects/add-status.vue') },
  { path: '/insurances/:search?', name: 'Insurances', meta: { title: 'Insurances' }, component: ()=>import(/* webpackChunkName: "insurances" */ '@/views/Insurances/insurances.vue') },
  { path: '/add-insurances/:id/:company/:user', name: 'addInsurances', meta: { title: 'Insurances' }, component: ()=>import(/* webpackChunkName: "addInsurances" */ '@/views/Insurances/add-insurance.vue') },
  { path: '/update-insurances/:id/:company/:show?/:user?/:insurance_id?', name: 'editInsurances', meta: { title: 'Insurances' }, component: ()=>import(/* webpackChunkName: "editIinsurances" */ '@/views/Insurances/add-insurance.vue') },
  { path: '/prices', name: 'Prices', meta: { title: 'Prices' }, component: ()=>import(/* webpackChunkName: "prices" */ '@/views/Insurances/prices.vue') },
  { path: '/prices/add-price', name: 'addPrice', meta: { title: 'Prices' }, component: ()=>import(/* webpackChunkName: "addPrices" */ '@/views/Insurances/add-price.vue') },
  { path: '/prices/:id/update-price/:show?', name: 'editPrice', meta: { title: 'Prices' }, component: ()=>import(/* webpackChunkName: "editPrices" */ '@/views/Insurances/add-price.vue') },
  { path: '/products', name: 'Products', meta: { title: 'Product' }, component: ()=>import(/* webpackChunkName: "products" */ '@/views/products/products.vue') },
  { path: '/product/add-product', name: 'addProduct', meta: { title: 'Product' }, component: ()=>import(/* webpackChunkName: "addproducts" */ '@/views/products/add-product.vue') },
  { path: '/product/:id/update-product', name: 'editProduct', meta: { title: 'Product' }, component: ()=>import(/* webpackChunkName: "editproducts" */ '@/views/products/add-product.vue') },
  { path: '/settings/insurers', name: 'Insurers', meta: { title: 'Insurers' }, component: ()=>import(/* webpackChunkName: "settings" */ '@/views/settings/Insurer/insurers.vue') },
  { path: '/settings/insurers/create-new', name: 'addInsurer', meta: { title: 'Insurers' }, component: ()=>import(/* webpackChunkName: "addinsurer" */ '@/views/settings/Insurer/new-insurer.vue') },
  { path: '/settings/insurers/:id/update-insurer', name: 'editInsurer', meta: { title: 'Insurers' }, component: ()=>import(/* webpackChunkName: "editinsurer" */ '@/views/settings/Insurer/new-insurer.vue') },
  { path: '/settings/themes', name: 'Themes', meta: { title: 'Theme' }, component: ()=>import(/* webpackChunkName: "themes" */ '@/views/settings/themes/themes.vue') },
  { path: '/settings/themes/add-theme', name: 'addTheme', meta: { title: 'Theme' }, component: ()=>import(/* webpackChunkName: "addtheme" */ '@/views/settings/themes/add-theme.vue') },
  { path: '/settings/themes/:id/update-theme', name: 'editTheme', meta: { title: 'Theme' }, component: ()=>import(/* webpackChunkName: "editheme" */ '@/views/settings/themes/add-theme.vue') },
  { path: '/settings/templates', name: 'pdfTemplates', meta: { title: 'PDF_templates' }, component: ()=>import(/* webpackChunkName: "pdftemplate" */ '@/views/settings/templates/template.vue') },
  { path: '/settings/add-template', name: 'addTemplate', meta: { title: 'PDF_templates' }, component: ()=>import(/* webpackChunkName: "addpdftemplate" */ '@/views/settings/templates/add-template.vue') },
  { path: '/settings/:id/update-template', name: 'editTemplate', meta: { title: 'PDF_templates' }, component: ()=>import(/* webpackChunkName: "edittemplate" */ '@/views/settings/templates/add-template.vue') },
  { path: '/settings/email-templates', name: 'emailTemplates', meta: { title: 'email_templates' }, component: ()=>import(/* webpackChunkName: "emailtemplate" */ '@/views/settings/email-template/email-template.vue') },
  { path: '/settings/add-email-template', name: 'addEmailTemplate', meta: { title: 'email_templates' }, component: ()=>import(/* webpackChunkName: "addemailtemplate" */ '@/views/settings/email-template/add-template.vue') },
  { path: '/settings/:id/update-email-template', name: 'editEmailTemplate', meta: { title: 'email_templates' }, component: ()=>import(/* webpackChunkName: "editemailtemplate" */ '@/views/settings/email-template/add-template.vue') },
  { path: '/trackers', name: 'Trackers', meta: { title: 'tracker' }, component: ()=>import(/* webpackChunkName: "tracker" */ '@/views/settings/trackers.vue') },
  { path: '/trackers/add-new', name: 'addTracker', meta: { title: 'tracker' }, component: ()=>import(/* webpackChunkName: "addtracker" */ '@/views/settings/add-tracker.vue') },
  { path: '/trackers/:id/update-tracker/:show?', name: 'editTracker', meta: { title: 'tracker' }, component: ()=>import(/* webpackChunkName: "editracker" */ '@/views/settings/add-tracker.vue') },
  { path: '/login', name: 'Login', meta: { title: 'login' }, component: ()=>import(/* webpackChunkName: "auth" */ '@/views/Login.vue') },
  { path: '/forget-password', name: 'forgetPassword', meta: { title: 'forgot_password' }, component: ()=>import(/* webpackChunkName: "auth" */ '@/views/forget-password.vue') },
  { path: '/profile', meta: { title: 'profile' }, name:'profile', component: () => import(/* webpackChunkName: "auth" */ '@/views/profile.vue')},
  { path: '/request', meta: { title: 'request' }, name:'request', component: () => import(/* webpackChunkName: "request" */ '@/views/requests.vue')},
  { path: '/add-request', meta: { title: 'request' }, name:'addRequest', component: () => import(/* webpackChunkName: "request" */ '@/views/add-request.vue')},
  { path: '/request/:id/update-request', meta: { title: 'request' }, name:'updateRequest', component: () => import(/* webpackChunkName: "request" */ '@/views/add-request.vue')},
  { path: '/request/:id/update-request/:show?', meta: { title: 'request' }, name:'updateRequest', component: () => import(/* webpackChunkName: "editrequest" */ '@/views/add-request.vue')},
  { path: '/conflicts', meta: { title: 'conflicts' }, name:'conflicts', component:()=> import(/* webpackChunkName: "conflicts" */ '@/views/conflicts.vue')},
  { path: '/tasks', meta: { title: 'tasks' }, name:'tasks', component:()=> import(/* webpackChunkName: "tasks" */ '@/views/tasks.vue')},
  { path: '/product/groups', meta: { title: 'product_groups' }, name:'productGroups', component:()=> import(/* webpackChunkName: "product-groups" */ '@/views/products/product-group.vue')},
  { path: '/product/add-group', meta: { title: 'product_groups' }, name:'addGroup', component:()=> import(/* webpackChunkName: "add-product-groups" */ '@/views/products/add-group.vue')},
  { path: '/product/:id/update-group', meta: { title: 'product_groups' }, name:'editProductGroup', component:()=> import(/* webpackChunkName: "edit-product-groups" */ '@/views/products/add-group.vue')},
  { path: '/road-help', meta: { title: 'roadHelp' }, name:'roadHelp', component:()=> import(/* webpackChunkName: "road-help" */ '@/views/road-help.vue')},
  { path: '/protector', meta: { title: 'Protector' }, name:'Protector', component:()=> import(/* webpackChunkName: "Protector" */ '@/views/Protector.vue')},
  { path: '/topdanmarkipid', meta: { title: 'topdanmarkipid' }, name:'Topdanmarkipid', component:()=> import(/* webpackChunkName: "Topdanmarkipid" */ '@/views/topdanmarkipid.vue')},
  { path: '/topdanmarkvilkaar', meta: { title: 'topdanmarkvilkaar' }, name:'Topdanmarkvilkaar', component:()=> import(/* webpackChunkName: "Topdanmarkvilkaar" */ '@/views/topdanmarkvilkaar.vue')}

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

// router.onError(error => {
//   if (/loading chunk \d* failed./i.test(error.message)) {
//     window.location.reload()
//   }
// })
router.beforeEach(async (to, from, next) => {
  to.meta.from_route = from.name
  to.meta.to_route = to.name
  const publicPages = ['/login', '/forget-password', '/road-help', '/protector', '/topdanmarkipid', '/topdanmarkvilkaar'];
  const restrictedPagesForCompany = ['companies', 'Products', 'addProduct', 'editProduct', 'addPrice', 'Prices', 'Insurers', 'addInsurer', 'editInsurer']
  const hasAccess = restrictedPagesForCompany.includes(to.name)
  const authRequired = !publicPages.includes(to.path);
  if (authRequired && !store.getters["auth/isAuthenticated"]) {
    await router.push({ path: '/login' });
  }else{
    if (to.path ==='/login' && store.getters["auth/isAuthenticated"]){
      if (!localStorage.getItem('token')) return next();
      return next({ name: 'Dashboard' })
    }
    if (store.getters["auth/getUser"].role === "company" && hasAccess) return next({ name: 'Dashboard' })
    next();
  }
})

export default router
