<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand @click="$router.push({name: 'Dashboard'})" style="cursor:pointer">
      <svg width="116" height="40" viewBox="0 0 116 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.7171 9.47234C40.4867 8.99355 40.1175 8.59587 39.658 8.33148C39.1985 8.0671 38.6702 7.94837 38.1423 7.99086C36.9422 8.07923 35.7493 8.2475 34.5714 8.49456C30.2016 9.44598 25.9937 11.0331 22.0808 13.2057L21.5201 13.4946C21.4242 13.5464 9.1033 20.7168 1.6001 32.1983L1.76241 32.3834C1.81405 32.3464 6.39565 29.1094 9.87797 26.5538C12.9274 31.4918 17.1774 35.5702 22.2284 38.4057L23.217 38.9094L24.1909 38.4057C24.8696 38.0649 40.7909 29.7686 41.0491 10.7242C41.0538 10.301 40.9604 9.88258 40.7761 9.50197M36.615 12.5834C36.1212 18.5973 33.5994 24.2622 29.466 28.6427C27.6122 30.6892 25.4901 32.4734 23.158 33.9464C20.8265 32.4695 18.7025 30.6857 16.8426 28.6427C15.5158 27.1907 14.3392 25.6073 13.3308 23.9168L14.2973 23.2131C17.4697 20.9909 22.8407 17.7834 23.4236 17.4353C28.4331 14.7094 32.845 13.0872 36.556 12.5909" fill="white"/>
        <path d="M17.7353 7.97746C17.0419 6.64059 16.8784 5.08967 17.2778 3.63672H17.1967C8.54255 4.92561 5.81278 8.8219 5.70211 8.97746L5.31845 9.53301V10.2145C5.29953 13.711 5.8631 17.1861 6.98584 20.496L7.03749 20.6589L10.6157 17.4886L10.5567 17.1626C10.0375 15.1729 9.74785 13.1299 9.69347 11.0738C10.6083 10.2738 12.9249 8.71079 17.7205 7.97746H17.7353Z" fill="white"/>
        <path d="M23.158 8.79863C23.8671 8.79863 24.5604 8.5875 25.15 8.19193C25.7397 7.79636 26.1993 7.23411 26.4707 6.57629C26.742 5.91848 26.813 5.19464 26.6747 4.49631C26.5363 3.79798 26.1948 3.15652 25.6934 2.65305C25.1919 2.14958 24.553 1.80671 23.8575 1.66781C23.162 1.5289 22.441 1.60019 21.7858 1.87267C21.1307 2.14515 20.5707 2.60656 20.1767 3.19858C19.7827 3.7906 19.5724 4.48662 19.5724 5.19863C19.5724 6.15341 19.9502 7.06909 20.6226 7.74422C21.295 8.41935 22.207 8.79863 23.158 8.79863Z" fill="#B7FB08"/>
        <path d="M65.3547 31.1616L65.0027 30.0352C64.6155 30.2112 64.2283 30.3168 63.8059 30.3168C63.2075 30.3168 62.6795 29.8944 62.6795 28.24V22.432C62.6795 20.4608 62.3627 18.9472 60.8491 17.7856C59.8987 17.0816 58.8427 16.7648 57.3995 16.7648C55.3931 17.152 52.8939 18.3488 51.5563 19.264C50.7819 19.8624 50.3243 20.32 50.3243 20.9536C50.3243 21.8688 51.5915 22.7136 52.5419 22.7136C53.2459 22.7136 53.7739 22.432 54.0203 21.5168C54.3019 20.4256 54.6187 19.5808 55.0059 19.088C55.3227 18.6656 55.7099 18.4544 56.2027 18.4544C57.4699 18.4544 58.3147 19.6864 58.3147 21.904V22.96C57.5051 23.664 54.7947 24.5088 52.7179 25.2832C50.8875 25.9872 50.0427 26.832 50.0427 28.6624C50.0427 30.8448 51.8379 33.168 54.7947 33.168C55.4987 33.168 57.1531 32.0064 58.4907 31.1264C58.6315 31.5488 58.8779 32.0064 59.3707 32.4288C59.8283 32.8512 60.5675 33.168 61.5179 33.168L65.3547 31.1616ZM58.3147 29.9648C57.7515 30.3872 57.3643 30.528 56.8363 30.528C56.0267 30.528 54.7243 29.7888 54.7243 27.8528C54.7243 26.6208 55.2875 25.9872 56.3787 25.424C57.1179 25.0368 57.6811 24.6848 58.3147 24.3328V29.9648Z" fill="white"/>
        <path d="M75.2437 32.7456V31.6192C73.1317 31.3728 72.9557 31.1616 72.9557 28.7328V16.7648C70.9845 17.328 68.6613 17.7856 66.5141 18.032V19.0528C68.2037 19.3344 68.4501 19.44 68.4501 21.7632V28.7328C68.4501 31.232 68.2389 31.3728 66.1973 31.6192V32.7456H75.2437Z" fill="white"/>
        <path d="M94.8424 30.704L94.068 30.6336C92.5896 30.4576 92.4136 30.3168 92.4136 28.3104V8C90.6184 8.528 87.8024 9.0208 85.5496 9.2672V10.3584C87.6968 10.64 87.908 10.7808 87.908 12.6816V16.9408C87.1336 16.8352 86.7464 16.7648 86.4648 16.7648C80.9736 16.7648 76.82 20.672 76.82 25.4592C76.82 30.704 80.9736 33.168 84.0008 33.168C84.388 33.168 84.916 33.0624 85.7608 32.5344L87.908 31.1616V33.2032C90.6888 32.3936 93.9976 31.9712 94.8424 31.8304V30.704ZM87.908 29.9296C87.1336 30.4224 86.6056 30.4928 85.972 30.4928C84.3528 30.4928 81.8184 28.8384 81.8184 24.5088C81.8184 19.1584 84.5288 18.2432 85.3384 18.2432C86.3944 18.2432 87.3448 18.8416 87.908 20.0736V29.9296Z" fill="white"/>
        <path d="M114.225 32.7456V31.6192C112.993 31.408 112.395 31.1264 111.268 29.824C110.177 28.4864 107.115 24.2976 106.164 22.7488C107.22 21.4112 107.713 20.8128 108.663 19.8624C109.649 18.8768 110.881 18.4192 113.063 18.032V16.976L105.46 17.2928V18.3488C106.798 18.56 106.939 18.9824 106.551 19.7568C106.129 20.496 105.249 21.9392 104.475 22.8192C103.947 23.3824 103.278 23.9808 102.433 24.1568V8C100.039 8.6688 97.5402 9.0912 95.921 9.2672V10.288C97.7866 10.4992 98.033 10.7104 98.033 12.6816V28.9792C98.033 31.2672 97.8218 31.3376 95.7098 31.6192V32.7456H104.756V31.6192C102.715 31.3376 102.433 31.2672 102.433 28.9792V25.1776C102.539 25.1776 102.679 25.2128 102.785 25.248C102.961 25.248 103.172 25.4592 103.489 25.8816C105.038 28.3808 107.009 31.5488 108.03 32.7808L114.225 32.7456Z" fill="white"/>
        <path d="M70.4715 14.4966C69.8247 14.4892 69.2059 14.2304 68.745 13.7746C68.2842 13.3188 68.0171 12.7015 68 12.0522C68.0029 11.726 68.07 11.4036 68.1974 11.1036C68.3249 10.8036 68.5101 10.5318 68.7426 10.3039C68.975 10.0761 69.25 9.89659 69.5518 9.77584C69.8536 9.65509 70.1762 9.59544 70.5011 9.60034C70.8253 9.59235 71.1478 9.65008 71.4494 9.77009C71.7509 9.8901 72.0252 10.0699 72.2559 10.2988C72.4866 10.5277 72.669 10.8009 72.7922 11.1022C72.9153 11.4035 72.9767 11.7266 72.9726 12.0522C72.9697 12.378 72.9026 12.7 72.7751 12.9997C72.6475 13.2993 72.4622 13.5706 72.2296 13.7979C71.9971 14.0252 71.722 14.2039 71.4202 14.3239C71.1185 14.4438 70.796 14.5025 70.4715 14.4966" fill="#B7FB08"/>
      </svg>




      <!--      <CIcon-->
<!--        custom-class-name="sidebar-brand-full"-->
<!--        :icon="logoNegative"-->
<!--        :height="35"-->
<!--      />-->
    </CSidebarBrand>
    <CSidebarNav class="navbar navbar-expand-lg pt-0">
          <CNavItem>
            <router-link :to="{name: 'Dashboard'}" style="text-decoration: none">
<!--            <CIcon custom-class-name="sidebar-brand-narrow" :icon="cilBriefcase" :height="20" />-->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3026_41851)">
                  <path d="M18.0583 5.79151C17.525 3.98317 16.0167 2.47484 14.2083 1.94151C12.8333 1.54151 11.8833 1.57484 11.225 2.06651C10.4333 2.65817 10.3417 3.72484 10.3417 4.48317V6.55817C10.3417 8.60817 11.275 9.64984 13.1083 9.64984H15.5C16.25 9.64984 17.325 9.55817 17.9167 8.76651C18.425 8.11651 18.4667 7.16651 18.0583 5.79151Z" fill="white"/>
                  <path d="M15.7584 11.133C15.5417 10.883 15.225 10.7413 14.9 10.7413H11.9167C10.45 10.7413 9.25837 9.54964 9.25837 8.08297V5.09964C9.25837 4.77464 9.1167 4.45797 8.8667 4.2413C8.62504 4.02464 8.2917 3.92464 7.97504 3.9663C6.0167 4.2163 4.2167 5.2913 3.0417 6.90797C1.85837 8.53297 1.42504 10.5163 1.80004 12.4996C2.3417 15.3663 4.63337 17.658 7.50837 18.1996C7.9667 18.2913 8.42504 18.333 8.88337 18.333C10.3917 18.333 11.85 17.8663 13.0917 16.958C14.7084 15.783 15.7834 13.983 16.0334 12.0246C16.075 11.6996 15.975 11.3746 15.7584 11.133Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_3026_41851">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>


              {{ $t('Dashboard') }}
            </router-link>
          </CNavItem>
<!--      <CNavItem v-show="isShown">-->
<!--            <router-link :to="{name: 'companies'}" style="text-decoration: none">-->
<!--            <CIcon custom-class-name="sidebar-brand-narrow" :icon="cilBriefcase" :height="20" />-->
<!--              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M16.4 6.15789H13.2V4.57895C13.2 3.70263 12.488 3 11.6 3H8.4C7.512 3 6.8 3.70263 6.8 4.57895V6.15789H3.6C2.712 6.15789 2.008 6.86053 2.008 7.73684L2 16.4211C2 17.2974 2.712 18 3.6 18H16.4C17.288 18 18 17.2974 18 16.4211V7.73684C18 6.86053 17.288 6.15789 16.4 6.15789ZM11.6 6.15789H8.4V4.57895H11.6V6.15789Z" fill="white"/>-->
<!--              </svg>-->

<!--              {{ $t('Companies') }}-->
<!--            </router-link>-->
<!--          </CNavItem>-->
      <CNavGroup v-if="isShown">
        <template #togglerContent>
          <!--              <CIcon custom-class-name="sidebar-brand-narrow" :height="20" :icon="cilCarAlt"/> -->
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4 6.15789H13.2V4.57895C13.2 3.70263 12.488 3 11.6 3H8.4C7.512 3 6.8 3.70263 6.8 4.57895V6.15789H3.6C2.712 6.15789 2.008 6.86053 2.008 7.73684L2 16.4211C2 17.2974 2.712 18 3.6 18H16.4C17.288 18 18 17.2974 18 16.4211V7.73684C18 6.86053 17.288 6.15789 16.4 6.15789ZM11.6 6.15789H8.4V4.57895H11.6V6.15789Z" fill="white"/>
          </svg>
          {{ $t('Companies') }}
        </template>
        <CNavItem><router-link :to="{name: 'companies'}" style="text-decoration: none"> {{ $t('Companies') }}</router-link></CNavItem>
        <CNavItem><router-link :to="{name: 'staff'}" style="text-decoration: none"> {{ $t('Staff') }}</router-link></CNavItem>
      </CNavGroup>
      <CNavItem v-if="!isShown && !isStaff"><router-link :to="{name: 'staff'}" style="text-decoration: none">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.4 8.8C5.2825 8.8 6 8.0825 6 7.2C6 6.3175 5.2825 5.6 4.4 5.6C3.5175 5.6 2.8 6.3175 2.8 7.2C2.8 8.0825 3.5175 8.8 4.4 8.8ZM15.6 8.8C16.4825 8.8 17.2 8.0825 17.2 7.2C17.2 6.3175 16.4825 5.6 15.6 5.6C14.7175 5.6 14 6.3175 14 7.2C14 8.0825 14.7175 8.8 15.6 8.8ZM16.4 9.6H14.8C14.36 9.6 13.9625 9.7775 13.6725 10.065C14.68 10.6175 15.395 11.615 15.55 12.8H17.2C17.6425 12.8 18 12.4425 18 12V11.2C18 10.3175 17.2825 9.6 16.4 9.6ZM10 9.6C11.5475 9.6 12.8 8.3475 12.8 6.8C12.8 5.2525 11.5475 4 10 4C8.4525 4 7.2 5.2525 7.2 6.8C7.2 8.3475 8.4525 9.6 10 9.6ZM11.92 10.4H11.7125C11.1925 10.65 10.615 10.8 10 10.8C9.385 10.8 8.81 10.65 8.2875 10.4H8.08C6.49 10.4 5.2 11.69 5.2 13.28V14C5.2 14.6625 5.7375 15.2 6.4 15.2H13.6C14.2625 15.2 14.8 14.6625 14.8 14V13.28C14.8 11.69 13.51 10.4 11.92 10.4ZM6.3275 10.065C6.0375 9.7775 5.64 9.6 5.2 9.6H3.6C2.7175 9.6 2 10.3175 2 11.2V12C2 12.4425 2.3575 12.8 2.8 12.8H4.4475C4.605 11.615 5.32 10.6175 6.3275 10.065Z" fill="white"/>
        </svg>
        {{ $t('Staff') }}</router-link>
      </CNavItem>

          <CNavItem>
            <router-link :to="{name: 'Users'}" style="text-decoration: none">
<!--              <CIcon custom-class-name="sidebar-brand-narrow" :icon="cilPeople" :height="20" /> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.4 8.8C5.2825 8.8 6 8.0825 6 7.2C6 6.3175 5.2825 5.6 4.4 5.6C3.5175 5.6 2.8 6.3175 2.8 7.2C2.8 8.0825 3.5175 8.8 4.4 8.8ZM15.6 8.8C16.4825 8.8 17.2 8.0825 17.2 7.2C17.2 6.3175 16.4825 5.6 15.6 5.6C14.7175 5.6 14 6.3175 14 7.2C14 8.0825 14.7175 8.8 15.6 8.8ZM16.4 9.6H14.8C14.36 9.6 13.9625 9.7775 13.6725 10.065C14.68 10.6175 15.395 11.615 15.55 12.8H17.2C17.6425 12.8 18 12.4425 18 12V11.2C18 10.3175 17.2825 9.6 16.4 9.6ZM10 9.6C11.5475 9.6 12.8 8.3475 12.8 6.8C12.8 5.2525 11.5475 4 10 4C8.4525 4 7.2 5.2525 7.2 6.8C7.2 8.3475 8.4525 9.6 10 9.6ZM11.92 10.4H11.7125C11.1925 10.65 10.615 10.8 10 10.8C9.385 10.8 8.81 10.65 8.2875 10.4H8.08C6.49 10.4 5.2 11.69 5.2 13.28V14C5.2 14.6625 5.7375 15.2 6.4 15.2H13.6C14.2625 15.2 14.8 14.6625 14.8 14V13.28C14.8 11.69 13.51 10.4 11.92 10.4ZM6.3275 10.065C6.0375 9.7775 5.64 9.6 5.2 9.6H3.6C2.7175 9.6 2 10.3175 2 11.2V12C2 12.4425 2.3575 12.8 2.8 12.8H4.4475C4.605 11.615 5.32 10.6175 6.3275 10.065Z" fill="white"/>
              </svg>
              {{ $t('Users') }}
            </router-link>
          </CNavItem>
          <CNavGroup>
            <template #togglerContent>
<!--              <CIcon custom-class-name="sidebar-brand-narrow" :height="20" :icon="cilCarAlt"/> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.9998 10.3641C17.9998 10.0494 17.9287 9.74881 17.8004 9.47381C17.7872 9.4039 17.7721 9.33409 17.7532 9.26328L16.3633 5.81895L16.3539 5.78796C15.8677 4.34006 15.1758 3 13.4213 3H6.71331C4.9156 3 4.28034 4.37327 3.78341 5.77992L2.32965 9.23265C2.11949 9.56938 2 9.95458 2 10.3641V11.4359C2 11.6016 2.01959 11.7637 2.05683 11.9198C2.02062 12.0141 2 12.1158 2 12.223V15.4107C2 15.8781 2.37894 16.2571 2.84639 16.2571H4.60278C5.06997 16.2571 5.44891 15.8781 5.44891 15.4107V13.8166H14.6512V15.4107C14.6512 15.8781 15.0302 16.2571 15.4977 16.2571H17.1535C17.6211 16.2571 18 15.8781 18 15.4107V12.2226C18 12.1158 17.9792 12.0137 17.9432 11.9197C17.9803 11.7633 18 11.6014 18 11.4358V10.3642H17.9998V10.3641ZM4.81487 6.12573C5.36828 4.56438 5.81331 4.08775 6.71331 4.08775H13.4209C14.3143 4.08775 14.8118 4.61935 15.3173 6.11901L16.3315 8.17428C16.0003 8.05165 15.6359 7.98359 15.253 7.98359H4.74678C4.40205 7.98359 4.07261 8.03903 3.76865 8.13936L4.81487 6.12573ZM5.6861 11.8429H3.83292C3.49926 11.8429 3.22865 11.5721 3.22865 11.2385C3.22865 10.9047 3.49926 10.634 3.83292 10.634H5.6861C6.0199 10.634 6.29051 10.9047 6.29051 11.2385C6.29051 11.5721 6.01972 11.8429 5.6861 11.8429ZM12.2325 11.7219H7.92198C7.71063 11.7219 7.53939 11.5505 7.53939 11.3393C7.53939 11.1277 7.71063 10.9564 7.92198 10.9564H12.2325C12.4441 10.9564 12.6153 11.1277 12.6153 11.3393C12.6153 11.5505 12.4441 11.7219 12.2325 11.7219ZM16.1202 11.8429H14.267C13.9331 11.8429 13.6626 11.5721 13.6626 11.2385C13.6626 10.9047 13.9333 10.634 14.267 10.634H16.1202C16.4541 10.634 16.7248 10.9047 16.7248 11.2385C16.7248 11.5721 16.4541 11.8429 16.1202 11.8429Z" fill="white"/>
              </svg>


              {{ $t('Objects') }}

            </template>
            <CNavItem><router-link :to="{name: 'Objects'}" style="text-decoration: none"> {{ $t('all_objects') }}</router-link></CNavItem>
            <CNavItem v-show="isShown"><router-link :to="{name: 'Types'}" style="text-decoration: none">{{ $t('types') }}</router-link></CNavItem>
            <CNavItem v-show="isShown"><router-link :to="{name : 'ObjectStatus'}" style="text-decoration: none">{{ $t('Status') }}</router-link></CNavItem>
          </CNavGroup>
          <CNavGroup v-if="isShown">
            <template #togglerContent>
<!--              <i class="cil-shield-check"></i>Insurances-->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.425 3.83806L10.8662 2.13055C10.3937 1.95648 9.62282 1.95648 9.15035 2.13055L4.59147 3.83806C3.71284 4.16962 3 5.19744 3 6.13408V12.8481C3 13.5195 3.43931 14.4064 3.97809 14.8043L8.53697 18.211C9.341 18.8161 10.6589 18.8161 11.463 18.211L16.0218 14.8043C16.5606 14.3981 16.9999 13.5195 16.9999 12.8481V6.13408C17.0082 5.19744 16.2954 4.16962 15.425 3.83806ZM12.8886 8.47984L9.32442 12.0441C9.20009 12.1684 9.0426 12.2264 8.88511 12.2264C8.72762 12.2264 8.57013 12.1684 8.4458 12.0441L7.11958 10.7013C6.8792 10.4609 6.8792 10.063 7.11958 9.82264C7.35995 9.58226 7.75782 9.58226 7.9982 9.82264L8.8934 10.7178L12.0183 7.59293C12.2587 7.35255 12.6566 7.35255 12.8969 7.59293C13.1373 7.8333 13.1373 8.23946 12.8886 8.47984Z" fill="white"/>
              </svg>

              {{ $t('Insurances') }}

<!--              <CIcon custom-class-name="sidebar-brand-narrow" :height="20" icon="cis-shield-check"/> Insurances-->
            </template>
            <CNavItem><router-link :to="{name: 'Insurances'}" style="text-decoration: none">{{$t('all_insurances')}}</router-link></CNavItem>
            <CNavItem v-show="isShown"><router-link :to="{name: 'Products'}" style="text-decoration: none">{{ $t('Product') }}</router-link></CNavItem>
            <CNavItem v-show="isShown"><router-link :to="{name: 'productGroups'}" style="text-decoration: none">{{ $t('product_groups') }}</router-link></CNavItem>
            <CNavItem><router-link :to="{name : 'Prices'}" style="text-decoration: none">{{ $t('Prices') }}</router-link></CNavItem>
          </CNavGroup>
      <CNavItem>
        <router-link class="tracker-active" :to="{name: 'Trackers'}" style="text-decoration: none">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.99986" cy="10.0001" r="6.48644" stroke="white" stroke-width="1.2973"/>
            <path d="M10 3.5135V2" stroke="white" stroke-width="1.2973" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 17.9998V16.4863" stroke="white" stroke-width="1.2973" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.4865 10L18 10" stroke="white" stroke-width="1.2973" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.99993 10L3.51343 10" stroke="white" stroke-width="1.2973" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="9.99981" cy="10.0001" r="3.24322" fill="white"/>
          </svg>

          {{ $t('Tracking') }}
        </router-link>
      </CNavItem>
          <CNavGroup>
            <template #togglerContent>
<!--              <CIcon custom-class-name="sidebar-brand-narrow" :height="20" icon="cil-settings"/> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6486 11.6125L16.3174 10.8438C16.4517 10.1188 16.4517 9.37503 16.3174 8.65003L17.6486 7.88128C17.8017 7.79378 17.8705 7.61253 17.8205 7.44378C17.4736 6.33128 16.883 5.32503 16.1111 4.48753C15.9924 4.3594 15.7986 4.32815 15.6486 4.41565L14.3174 5.1844C13.758 4.70315 13.1142 4.33128 12.4174 4.08753V2.55315C12.4174 2.37815 12.2955 2.22503 12.1236 2.18753C10.9767 1.93128 9.80175 1.94378 8.71113 2.18753C8.53925 2.22503 8.41738 2.37815 8.41738 2.55315V4.09065C7.72363 4.33753 7.07988 4.7094 6.51738 5.18753L5.18925 4.41878C5.03613 4.33128 4.8455 4.3594 4.72675 4.49065C3.95488 5.32503 3.36425 6.33128 3.01738 7.4469C2.96425 7.61565 3.03613 7.7969 3.18925 7.8844L4.5205 8.65315C4.38613 9.37815 4.38613 10.1219 4.5205 10.8469L3.18925 11.6157C3.03613 11.7032 2.96738 11.8844 3.01738 12.0532C3.36425 13.1657 3.95488 14.1719 4.72675 15.0094C4.8455 15.1375 5.03925 15.1688 5.18925 15.0813L6.5205 14.3125C7.07988 14.7938 7.72363 15.1657 8.4205 15.4094V16.9469C8.4205 17.1219 8.54238 17.275 8.71425 17.3125C9.86113 17.5688 11.0361 17.5563 12.1267 17.3125C12.2986 17.275 12.4205 17.1219 12.4205 16.9469V15.4094C13.1142 15.1625 13.758 14.7907 14.3205 14.3125L15.6517 15.0813C15.8049 15.1688 15.9955 15.1407 16.1142 15.0094C16.8861 14.175 17.4767 13.1688 17.8236 12.0532C17.8705 11.8813 17.8017 11.7 17.6486 11.6125V11.6125ZM10.4174 12.2469C9.03925 12.2469 7.91738 11.125 7.91738 9.7469C7.91738 8.36878 9.03925 7.2469 10.4174 7.2469C11.7955 7.2469 12.9174 8.36878 12.9174 9.7469C12.9174 11.125 11.7955 12.2469 10.4174 12.2469Z" fill="white"/>
              </svg>
              {{ $t('Settings') }}
            </template>
            <CNavItem v-show="isShown"><router-link :to="{name: 'Insurers'}" style="text-decoration: none">{{ $t('Insurers') }}</router-link></CNavItem>
            <CNavItem><router-link :to="{name: 'Themes'}" style="text-decoration: none">{{ $t('Theme') }}</router-link></CNavItem>
            <CNavItem v-show="isShown"><router-link :to="isShown ? { name: 'pdfTemplates' } : { name: 'addTemplate' }" style="text-decoration: none">{{ $t('PDF_templates') }}</router-link></CNavItem>
            <CNavItem v-show="isShown"><router-link :to=" {name: 'emailTemplates' }" style="text-decoration: none">{{ $t('email_templates') }}</router-link></CNavItem>
          </CNavGroup>
      <CNavItem>
        <router-link :to="{name: 'request'}" style="text-decoration: none">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3766 4.97895H6.46407C5.99738 4.97895 5.61865 4.60023 5.61865 4.13354V2.84542C5.61865 2.37872 5.99738 2 6.46407 2H11.3766C11.8433 2 12.222 2.37872 12.222 2.84542V4.13354C12.222 4.6008 11.8433 4.97895 11.3766 4.97895Z" fill="white"/>
            <path d="M17.0578 11.1344C16.5323 10.6089 15.6754 10.6089 15.1499 11.1344L14.5261 11.7616L16.4352 13.6701L17.0578 13.0475C17.589 12.5168 17.589 11.6656 17.0578 11.1344Z" fill="white"/>
            <path d="M13.9215 12.3691L10.5912 15.6937C10.5227 15.7679 10.477 15.8593 10.4598 15.9565L10.2085 17.4359C10.1514 17.7615 10.437 18.0414 10.7626 17.99L12.2421 17.7387C12.3392 17.7215 12.4306 17.6758 12.4991 17.6073L15.8294 14.277L13.9215 12.3691Z" fill="white"/>
            <path d="M13.7901 3.49023H13.079V4.13287C13.079 5.07139 12.3152 5.83513 11.3767 5.83513H6.46415C5.52505 5.83513 4.76189 5.07139 4.76189 4.13287V3.49023H4.03928C2.91396 3.49023 2 4.4042 2 5.52952V15.9544C2 17.0797 2.91396 17.9937 4.03928 17.9937H9.4414C9.35457 17.7704 9.32197 17.5282 9.36424 17.2865L9.61505 15.8111C9.6596 15.5494 9.77956 15.3078 9.96007 15.1107L13.5959 11.48L14.5413 10.5295C14.8926 10.1776 15.3433 9.96908 15.8283 9.90914V5.53523C15.8294 4.39849 14.9211 3.49023 13.7901 3.49023ZM8.03103 11.9633H5.55247C5.26857 11.9633 5.03836 11.733 5.03836 11.4492C5.03836 11.1652 5.26857 10.9351 5.55247 10.9351H8.03103C8.31493 10.9351 8.5451 11.1652 8.5451 11.4492C8.5451 11.733 8.31493 11.9633 8.03103 11.9633ZM11.0654 9.04544H5.55304C5.26914 9.04544 5.03893 8.8152 5.03893 8.53134C5.03893 8.24742 5.26914 8.01722 5.55304 8.01722H11.0654C11.3493 8.01722 11.5795 8.24742 11.5795 8.53134C11.5795 8.8152 11.3493 9.04544 11.0654 9.04544Z" fill="white"/>
          </svg>
          {{ $t('request') }}
        </router-link>
      </CNavItem>
      <CNavItem>
        <router-link :to="{name: 'conflicts'}" style="text-decoration: none">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.75922 10.1183C4.42522 9.87626 4.15722 10.0203 4.15722 10.4443V12.1043L0.767219 12.5803C0.447219 12.6323 0.199219 12.9203 0.199219 13.2663C0.199219 13.6123 0.447219 13.9003 0.767219 13.9523L4.15922 14.4363V16.0883C4.15922 16.5123 4.42722 16.6563 4.76122 16.4143L8.44522 13.7103C8.77922 13.4623 8.77922 13.0703 8.44522 12.8283L4.75922 10.1183Z" fill="white"/>
            <path d="M19.2372 12.5803L15.8392 12.1043V10.4443C15.8392 10.0203 15.5712 9.87626 15.2372 10.1183L11.5532 12.8223C11.2192 13.0703 11.2192 13.4683 11.5532 13.7103L15.2392 16.4163C15.5732 16.6583 15.8412 16.5143 15.8412 16.0903V14.4383L19.2312 13.9543C19.5512 13.9023 19.7992 13.6143 19.7992 13.2683C19.7992 12.9203 19.5572 12.6403 19.2372 12.5803Z" fill="white"/>
            <path d="M10.2985 3.46582C9.93853 3.46582 9.64453 3.75782 9.64453 4.11982V5.53782C9.64453 5.89782 9.93653 6.19182 10.2985 6.19182C10.6605 6.19182 10.9525 5.89982 10.9525 5.53782V4.11982C10.9525 3.75982 10.6605 3.46582 10.2985 3.46582Z" fill="white"/>
            <path d="M13.1034 8.32214C13.2714 8.32214 13.4414 8.25614 13.5694 8.12814L14.9674 6.71014C15.2214 6.45414 15.2174 6.04014 14.9614 5.78614C14.7034 5.53414 14.2914 5.53614 14.0374 5.79214L12.6394 7.21014C12.3854 7.46614 12.3894 7.88015 12.6454 8.13415C12.7714 8.25815 12.9374 8.32214 13.1034 8.32214Z" fill="white"/>
            <path d="M7.03977 8.12741C7.16777 8.25741 7.33577 8.32141 7.50377 8.32141C7.66977 8.32141 7.83577 8.25741 7.96377 8.13141C8.21977 7.87741 8.22177 7.46341 7.96777 7.20741L6.59577 5.82141C6.34177 5.56541 5.92777 5.56541 5.67177 5.81741C5.41577 6.07141 5.41377 6.48541 5.66777 6.74141L7.03977 8.12741Z" fill="white"/>
          </svg>

          {{ $t('conflicts') }}
          <CBadge color="danger" class="notification-badge" v-if="conflict_count">{{ conflict_count }}</CBadge>
        </router-link>
      </CNavItem>
<!--      <CNavItem>-->
<!--        <router-link :to="{name: 'tasks'}" style="text-decoration: none">-->
<!--          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M5.94141 0.492527C6.32813 0.840183 6.35937 1.43003 6.01172 1.81675L3.19922 4.94175C3.02734 5.13315 2.78516 5.24643 2.52734 5.25034C2.26953 5.25425 2.02344 5.15659 1.83984 4.9769L0.273437 3.4144C-0.0898438 3.04721 -0.0898438 2.45346 0.273437 2.08628C0.636719 1.71909 1.23438 1.71909 1.59766 2.08628L2.46094 2.94956L4.61328 0.558933C4.96094 0.172214 5.55078 0.140964 5.9375 0.48862L5.94141 0.492527ZM5.94141 6.74253C6.32813 7.09018 6.35937 7.68003 6.01172 8.06675L3.19922 11.1917C3.02734 11.3832 2.78516 11.4964 2.52734 11.5003C2.26953 11.5042 2.02344 11.4066 1.83984 11.2269L0.273437 9.6644C-0.09375 9.29721 -0.09375 8.70346 0.273437 8.34018C0.640625 7.9769 1.23438 7.973 1.59766 8.34018L2.46094 9.20346L4.61328 6.81284C4.96094 6.42612 5.55078 6.39487 5.9375 6.74253H5.94141ZM8.75 2.75034C8.75 2.05893 9.30859 1.50034 10 1.50034H18.75C19.4414 1.50034 20 2.05893 20 2.75034C20 3.44175 19.4414 4.00034 18.75 4.00034H10C9.30859 4.00034 8.75 3.44175 8.75 2.75034ZM8.75 9.00034C8.75 8.30893 9.30859 7.75034 10 7.75034H18.75C19.4414 7.75034 20 8.30893 20 9.00034C20 9.69175 19.4414 10.2503 18.75 10.2503H10C9.30859 10.2503 8.75 9.69175 8.75 9.00034ZM6.25 15.2503C6.25 14.5589 6.80859 14.0003 7.5 14.0003H18.75C19.4414 14.0003 20 14.5589 20 15.2503C20 15.9417 19.4414 16.5003 18.75 16.5003H7.5C6.80859 16.5003 6.25 15.9417 6.25 15.2503ZM1.875 13.3753C2.37228 13.3753 2.84919 13.5729 3.20083 13.9245C3.55246 14.2761 3.75 14.7531 3.75 15.2503C3.75 15.7476 3.55246 16.2245 3.20083 16.5762C2.84919 16.9278 2.37228 17.1253 1.875 17.1253C1.37772 17.1253 0.900805 16.9278 0.549175 16.5762C0.197544 16.2245 -1.11759e-08 15.7476 -1.11759e-08 15.2503C-1.11759e-08 14.7531 0.197544 14.2761 0.549175 13.9245C0.900805 13.5729 1.37772 13.3753 1.875 13.3753Z" fill="white"/>-->
<!--          </svg>-->
<!--          {{ $t('tasks') }}-->
<!--        </router-link>-->
<!--      </CNavItem>-->
<!--          <b-dropdown id="dropdown-1" text="Settings" class="m-md-2">-->
<!--            <b-dropdown-item :to="{name: 'Insurers'}">Insurers</b-dropdown-item>-->
<!--            <b-dropdown-item>Themes</b-dropdown-item>-->
<!--          </b-dropdown>-->
    </CSidebarNav>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import showErrors from "@/mixins/showErrors";

export default {
  name: 'AppSidebar',
  components: {
    // AppSidebarNav,
  },
  mixins: [showErrors],
  setup() {
    const store = useStore();
    let visible = false;

    return {
      logoNegative,
      isStaff: computed( ()=> store.getters['auth/isStaff']),
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      conflict_count: computed(() => store.getters['miscs/getCounts']),
      visible,
    }
  },
}
</script>
