<template>
  <CDropdown variant="btn-group" @click="null">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem v-if="!isShown" @click="$router.push({ name: 'profile' })"> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem @click="logoutUser"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/avatar.png'
import showErrors from "@/mixins/showErrors";
export default {
  name: 'AppHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42,
    }
  },
  computed:{
    avatar(){
      return this.$store.getters["auth/getUser"].companyLogo !== null ? this.$store.getters["auth/getUser"].companyLogo : avatar
    }
  },
  mixins: [showErrors],
  methods:{
    async logoutUser(){
      await this.$store.dispatch('auth/loggingOut')
    }
  }
}
</script>

<style scoped lang="scss">
.header-nav{
  .btn-group{
    .show, .btn:hover{ border-color: transparent !important; }
    .dropdown-item{ cursor: pointer !important; }
  }
}
</style>
