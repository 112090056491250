<template>
  <div class="position-relative">
    <div v-if="getLoader" id="spinner" class="loader-backdrop">
      <div class="spinner-wrapper">
        <CSpinner color="primary"/>
      </div>
    </div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
<!--      <AppFooter />-->
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
// import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    // AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  computed:{
    getLoader(){
      return  this.$store.getters['auth/getLoader']
    }
  }

}
</script>
