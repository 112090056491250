import store from "@/store"
export default {
  computed: {
    isShown() {
      return this.$store.getters["auth/isAdmin"]
    },
    isStaff() {
      return this.$store.getters["auth/isStaff"]
    },
  },
  methods: {
    showObjectMessage(data, type){
      if (Array.isArray(data)){
        data.forEach(err =>{
          err = err.replace("itemJson.", '')
          this.$toast.show(err, {
            type: type,
            position: "top-right"
          });
        })
      }else if (typeof data === "string"){
        this.$toast.show(data, {
          type: type,
          position: "top-right"
        });
      }
    },
    showItemMessage(data, type){
      if (Array.isArray(data)){
        data.forEach((err) =>{
          err = err.replace(/[.0-9]/g, ' ')
          this.$toast.show(err, {
            type: type,
            position: "top-right"
          });
        })
      }else if (typeof data === "string"){
        this.$toast.show(data, {
          type: type,
          position: "top-right"
        });
      }
    },
    showMessage(data, type){
      if (Array.isArray(data)){
        data.forEach(err =>{
          this.$toast.show(err, {
            type: type,
            position: "top-right"
          });
        })
      }else if (typeof data === "string"){
        this.$toast.show(data, {
          type: type,
          position: "top-right"
        });
      }
    },
     showSpinner(val) {
       store.commit('auth/CHANGE_LOADER', val)
    },
    convertToCommaSeparated(val) {
      return val?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || val;
    },
  }
}

