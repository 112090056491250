import {createI18n} from "vue-i18n";
import en from "@/assets/localization/en.json";
import da from "@/assets/localization/dk.json";

const i18n = createI18n({
  locale:  localStorage.getItem('lang') || 'en',
  fallbackLocale: "en",
  legacy: false,
  messages:{
    en: en,
    da: da
  }
})
export default i18n
