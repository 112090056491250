import {http} from "@/config/httpCommon";

const state = {
  conflict_count: 0,
  statuses: [],
  mode:{},
  insurance_view:{},
  insuranceUser:{}
}
const getters = {
  getStatuses: state => state.statuses,
  getMode: state => state.mode,
  getInsuranceView: state => state.insurance_view,
  getCounts: state => state.conflict_count,
  getInsuranceUser: state => state.insuranceUser
}
const actions = {
  async getStatuses({ commit }){
    const res  = await http.get('object-statuses/list').catch(err => console.log("error in getting statuses in app vue", err.response.data.message))
    await commit('SET_STATUSES', res?.data?.data)
  },
  async getCounter({ commit }){
    const res  = await http.get('conflicts/conflictscount').catch(err => console.log("error in getting statuses in app vue", err.response.data.message))
    if (res && res.data) await commit('SET_COUNTS', res?.data)
  }
}
const mutations = {
  SET_STATUSES(state, data){
    state.statuses = data
  },
  SET_COUNTS(state, data){
    state.conflict_count = data
  },
  SET_MODE(state, data){
    state.mode = data
  },
  SET_INSURANCE_VIEW(state, data){
    state.insurance_view = data
  },
  SET_INSURANCE_USER(state, data){
    state.insuranceUser = data
  }
}
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
