import router from '@/router'
const state = {
  token: localStorage.getItem("token") || '',
  user:  JSON.parse(localStorage.getItem("user")) || {},
  language: '',
  loader: false,
  userState: localStorage.getItem('userState') || {},
  isAdminLoggedIn:  false
}
const getters = {
  isAuthenticated: state => !!state.token,
  getUser: state => state.user,
  isAdmin: state => state.user?.role === 'admin',
  isStaff: state => state.user?.role === 'staff',
  getLanguage: state => state.language || localStorage.getItem('lang') || 'en',
  getLoader: state => state.loader,
  getUserState: state => state.userState,
  getAdminLogin: state => state.isAdminLoggedIn || localStorage.getItem('isAdminLoggedIn')
}
const actions = {
  async loggingOut({ commit }){
    await commit('logoutUser')
    router.push({name: 'Login'})
  },
  changeLanguage({ commit }, lang) {
    commit('CHANGE_LANG', lang)
  },
}
const mutations = {
  setUser(state, payload) {
    if (payload) {
      state.token = payload.accessToken
      localStorage.setItem('token', payload.accessToken)
      state.user = payload
      localStorage.setItem('user', JSON.stringify(payload))
      if (payload.role === 'admin' && !payload.isAdminLogged){
        localStorage.setItem('userState', JSON.stringify(payload))
      }
    }
  },
  logoutUser(state) {
    state.token = ''
    state.user = {}
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('userState')
    localStorage.removeItem('isAdminLoggedIn')
  },
  CHANGE_LANG(state, lang){
    state.language = lang
    localStorage.setItem('lang', lang)
  },
  CHANGE_LOADER(state, loader){
    state.loader = loader
  },
  CHANGE_ADMIN_LOGIN(state, val){
    localStorage.setItem('isAdminLoggedIn', val)
    state.isAdminLoggedIn = val
  }
}
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
